import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
  CTileImg,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ザ ロイヤルパークホテル 京都三条",
          date: "2021年11月24日",
          title: <>10年のその裏側に、わたしの“マイルール”</>,
          text: <>ザ ロイヤルパークホテル 京都三条／川口 泰宜 総支配人</>,
          img: {
            src: "/assets/images/journal/article02/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article02/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                <p>歴史の面影が残る建物や観光名所がひしめき合う、京都。</p>
                <p>
                  かつて都がおかれ、政治、商業、娯楽、さまざまな文化がこの街を中心に発信され、多くの者が足を運んだ—。やがて客人をもてなす宿が軒を連ね、“おもてなしの街”として今では世界に誇る日本の観光地だ。
                </p>
                <p>
                  そんなおもてなしの源を探りに、やってきたのは今年10月にめでたく開業10周年を迎えたザ
                  ロイヤルパークホテル
                  京都三条だ。長きにわたり愛されてきた同ホテルには、人々を惹きつけてやまない独自のおもてなしの心やサービス、その裏側で大切にしてきた“マイルール”があるのではないだろうか。ホテル開業当時のことをよく知る、川口泰宜
                  総支配人のマイルールからその秘密を紐解いていきたい。
                </p>
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article02/img_chapter01.png",
            },
            imgSp: {
              src: "/assets/images/journal/article02/img_chapter01__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>背筋がしゃんとする、</span>
                <span>朝のルーティーン</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  ザ ロイヤルパークホテル
                  京都三条の川口総支配人の朝は、いつものルーティーンから始まる。
                </>
              ),
            }}
          />
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "7:30",
                text: <>起床</>,
              },
            ]}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 01</p>
            <h3 className="c_headingLv3">コップ一杯の麦茶を飲む</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  「朝は、目覚めに1杯の麦茶を口にします。休日も同じ時間に起きるもののこの習慣は仕事のある日だけ、不思議ですね」そう優しく丁寧な口調で語る川口総支配人。誰しも仕事へのスイッチは持っているだろうが、彼にとっての朝の一杯は、切り替えのスイッチ。身も心も「ホテルマン」へとなっていくためのルーティーンなのかも知れない。身支度を整えたらさあ、いざ出勤だ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter01_01.png",
              alt: "",
            }}
            caption={
              <>
                木屋町通りの高瀬川に並ぶ桜並木。春にはうすピンク、夏には緑のカーテンが美しい
              </>
            }
          />
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "8:10",
                text: <>出勤</>,
              },
            ]}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 02</p>
            <h3 className="c_headingLv3">最寄り駅からお気に入りの道を歩く</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    京都の朝は実に清々しい。それは祇園や河原町など夜の賑わいとはまるで違う、朝の光に照らされた風情ある一面を見せるからだろう。街を歩けば、遠くには夏の風物詩「京都五山送り火」で知られる大文字山が、そして京都の人々の憩いの場である、鴨川の悠然とした姿が臨める。
                  </p>
                  <p>
                    いつも利用するという京都河原町駅からホテルまでは、歩いて5〜10分ほど。「その日の気分で歩くルートを決めます。京都は四季を肌で感じられる街。些細なことでもふと気づくことがあると、朝礼やお客様との会話のきっかけにもなりますから」。
                  </p>
                  <p>
                    日本文化が息づく街に身をおくとは、まさにこういうことであろう。
                    美しい葉の色彩の移り変わりや川辺の動物たちの姿、神社仏閣の祭りや年中行事の飾りなど。自分の肌で、そして目で得る情報はなににも変えがたいという。そう、ここ京都には生きた情報が溢れているのである。
                  </p>
                </>
              ),
            }}
          />
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "8:45",
                text: <>ホテル到着</>,
              },
            ]}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 03</p>
            <h3 className="c_headingLv3">水筒で持参したコーヒーを一口飲む</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ホテル総支配人の仕事とはなにか。わたしたちが目にする、フロントで出迎えてくれる様子やスマートな身のこなしを想像する人も多いだろうが、川口総支配人の日々の業務は意外にもパソコンに向き合う時間が多いというではないか。
                    「総支配人になってからは、PCに向かう時間が増えました。現場に出ている時は、動きがあるため業務ごとに切り替えもできますが、デスクワークが多い今はつい業務にのめり込んでしまいます。そんな時に、マイボトルで飲むコーヒーは気分転換なんです。」
                  </p>
                  <p>
                    PCを立ち上げるとき、メールを返信するたびにまた一口……。朝、自分でドリップして淹れたコーヒーは、一日の業務が終わる頃にはほどよくなくなるのだという。
                  </p>
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article02/img_chapter01_02.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article02/img_chapter01_03.png",
                  alt: "",
                },
              },
            ]}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article02/img_chapter02.png",
            },
            imgSp: {
              src: "/assets/images/journal/article02/img_chapter02__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span>見えない仕事にこそ宿る、</span>
                <span>おもてなしの心</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "9:10",
                text: <>ブリーフィング</>,
              },
              {
                label: "9:30",
                text: <>メールチェックなどデスク業務</>,
              },
            ]}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 04</p>
            <h3 className="c_headingLv3">
              デスク業務のかたわら、ロビーの様子をチェック
            </h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  ホテルの快適さを決定づけるものはなんだろうか。
                  立地、客室の広さや、清潔さ……いや、もしかしたらそこで触れ合う人とのコミュニケーションではないだろうか。フロントでの丁寧で流れるようなやりとりに、息のぴったりとあったスタッフたちの動きは、見ているだけで心地がよい。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article02/img_chapter02_01.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article02/img_chapter02_02.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  デスクワークのかたわらでも、川口総支配人は執務室のモニターを気にかけている。「職業病です」そう微笑みながら話すが、少しでもお客様をお待たせしているときには、業務の手を止め表に出るという習慣が染み付いている。現場の仕事から離れた今でも視点は常にお客様。「できることは限られるけれど、まずは目を配る。諸先輩方もそうしてくださっていたように、自分もそうありたいです」。まっすぐな瞳で語る言葉。これこそが、見えない“真のおもてなし”なのだ。
                </>
              ),
            }}
          />
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "11:30",
                text: <>ホテル巡回</>,
              },
            ]}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 05</p>
            <h3 className="c_headingLv3">客室を中心に館内を巡回</h3>
          </CQuoteBox>
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article02/img_chapter02_03.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article02/img_chapter02_04.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                客室の設えだけでなく、窓から見える風景もおもてなしのひとつ。鴨川と京の街並みが眼下に広がる
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ホテルの客室に入ったとき、なぜだか「帰ってきた」そう感じる瞬間がたまにある。一歩足を踏み入れた時から、くつろぎが用意され、清潔で落ち着ける空間。やはりここにもなにか秘密があるはずだ。
                  </p>
                  <p>
                    お客様のチェックアウトがひと段落し次のお客様を迎えるまでの間、ホテルはひっそりと身支度を整える。お迎えするお客様へのおもてなしの心は、シワひとつ無いベッドシーツ、ほこりひとつ無いランプシェードにも表れる。清掃スタッフが細部まで綺麗に磨き上げ、備品の補充を終えた客室を、川口総支配人は見て回る。なにをチェックしているのかと問いかけると「やっぱり清掃が行き届いているかは気になります」と。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter02_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  川口総支配人の視点は、やはりわたしたち宿泊客と同じなのだ。お客様視点があるからこそ、そこに“気づき”がある。普段何気なく感じている居心地の良さの奥底には、清掃スタッフのおもてなしと、川口総支配人のお客様視点がある。
                  次のお客様を迎える準備は着々と。さて、お次はどこへ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter02_06.png",
              alt: "",
            }}
          />
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "12:00",
                text: <>昼食</>,
              },
            ]}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 06</p>
            <h3 className="c_headingLv3">妻の手作り弁当を美味しくいただく</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  正午はホッと一息つく束の間の昼食時間。聞けば、川口総支配人は愛知県の出身。かつて名古屋のホテルでお世話になった前総支配人のご縁で、京都へ移住し、それから10年—。移住という大きな決断を後押ししたのは、一番側で見守ってきた妻だった。「前みたいに楽しそうに、仕事の話をしなくなったね」そう妻から指摘されたことがきっかけとなり、本当に働きたい場所、自分にとってあるべき姿を考え直したという。10年前の妻の言葉がなければ、もしかしたら今ここにはいないかも知れない。ふと、そんな想いにふけりながら今日も愛妻弁当を美味しくいただく。
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article02/img_chapter03.png",
            },
            imgSp: {
              src: "/assets/images/journal/article02/img_chapter03__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span>向き合うことで生まれる、</span>
                <span>居心地の良さ</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "13:00",
                text: <>デスク業務/巡回</>,
              },
              {
                label: "15:00",
                text: <>WEB会議／チェックイン開始</>,
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    さあ、いよいよチェックインの時間だ。期待に胸を膨らませたお客様が続々と来館する。
                  </p>
                  <p>「いいホテルとはなんですか？」</p>
                  <p>
                    こんな問いを投げかけてみると、「家族」そう一言川口総支配人から答えが返ってきた。お客様にとってホテルのスタッフが家族、そしてホテルのスタッフにとっても家族のような存在だと。「ありがとうございます」「いってらっしゃいませ」「おかえりなさいませ」そんな言葉がそっと贈られる。たとえ一泊だけの滞在でも変わらない。またここに来たい、そう思っていただけるような家族の「居場所」がホテルなのである。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter03_01.png",
              alt: "",
            }}
            caption={
              <>
                朝食会場にもなっているレストラン「ムッシュ
                いとう」。京都の地のものを味わってもらうため、コメは八代目儀兵衛、漬物は京野菜、味噌汁は白味噌と朝から幸せな気持ちにさせてくれる
              </>
            }
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article02/img_chapter03_02.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article02/img_chapter03_03.png",
                  alt: "",
                },
              },
            ]}
          />
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "16:00",
                text: <>デスク業務</>,
              },
            ]}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 07</p>
            <h3 className="c_headingLv3">お客様の口コミには日頃から目を通す</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ここ数年で、ホテルの評価方法は大きく変化した。予約サイトやネット上には、口コミが溢れ、その評価がホテルの誘客へ大きく作用するのだ。評価を気にするなど、働く側からするとさぞ窮屈だろう……、そんな風に思っていたが川口総支配人からはこんな言葉が出てきた。「フロントでただ単にチェックイン業務をしていればいいのではない。流れ作業にならないよう、スタッフ一人ひとりが意識してできることをやりましょうと常々伝えています。その一つの評価の指標として口コミがあるのだと思っています」。
                  </p>
                  <p>
                    口コミには“教えてもらうこと”のほうが圧倒的に多いのだそう。たとえば、お客様からの厳しいご意見も、その言葉の裏側にはホテルへの想いがある。これまでもそんな意見を一つひとつすくいあげ、次へつなげてきた。そうやって、周囲からの視点、内部からの視点の両方をバランス良く持ち合わせているホテルだからこそ、成長を続けていけるのだろう。
                  </p>
                </>
              ),
            }}
          />
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "18:00",
                text: <>スタッフ面談</>,
              },
            ]}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 08</p>
            <h3 className="c_headingLv3">
              スタッフと積極的にコミュニケーション
            </h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  ホテルの現場経験が長い川口総支配人には、彼ならではの信念がある。
                  「お互いの思っていることを、腹を割って話さないことには話が進まない」。ホテルを監督する立場として、社内の風通しの良さには人一倍気を使う。少しでも時間が空けば、スタッフとの面談や日々の業務報告、相談にも耳を傾ける。「若い頃は自分から『ちょっといいですか？』と上司を呼び止めることも多々ありました。これまで自分が先輩方にしていただいたように、わたしも自ら積極的に声をかけ、現場を重視するようにしています。」
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter03_04.png",
              alt: "",
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article02/img_chapter04.png",
            },
            imgSp: {
              src: "/assets/images/journal/article02/img_chapter04__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "04",
            },
            title: (
              <>
                <span>オフの時間があるから</span>
                <span>こそ、仕事に打ち込める</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CLabelTextList
            exClass={"u_mb25"}
            data={[
              {
                label: "19:00",
                text: <>業務終了</>,
              },
              {
                label: "20:00",
                text: <>帰宅／家族との時間、一人の時間を楽しむ</>,
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  仕事を終え外に出ると、京都の街はすっかり大人の色気を纏い、社交場へと顔をかえている。賑わう京の街を横目に、家路を急ごうではないか。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter04_01.png",
              alt: "",
            }}
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 09</p>
            <h3 className="c_headingLv3">娘の手紙に癒される</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「なんでも持ち歩くんです」。照れながら、そっと鞄から出したのは小学生の娘が書いた手紙。カラフルで可愛らしい便箋に、手書きの文字や絵が描かれている。帰宅をするとたまに食卓においてあるため、鞄の中に入れて時間があるときに読むのだ。
                  </p>
                  <p>「とと ありがとう」</p>
                  <p>
                    手書きで一生懸命に綴られた手紙に、仕事や日々の疲れから解放され、癒されるのだと目を細めて語る。「やっぱり家族のありがたみじゃないですけれども、頑張らなきゃいけないなって思いますね。家族あっての仕事ですから」。きりりとした印象の川口総支配人の日々の仕事を支えるのは、やはり家族の愛情なのだろう。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter04_02.png",
              alt: "",
            }}
            caption={
              <>
                文化的なものに触れられる環境は、子どもにとって最高の学び場。子育て、夫婦、家族のあり方さえもこの街は変えてくれたのかもしれない。
              </>
            }
          />
          <CQuoteBox>
            <p className="u_mb0">#Rule 10</p>
            <h3 className="c_headingLv3">趣味の時間も大切にする</h3>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    一日の終わりには趣味の時間で至福のときを過ごす。唯一ひとりの時間を満喫できるのは、オンライン動画サービスでアニメや映画、ドラマを楽しむ時間だ。子どもの頃から大好きだったアニメは、なぜか京都に赴任してから再び観るようになった。幸せな気分で一日を締めくくるため、とプライベートな顔を見せるが、最近ではホテルの若手スタッフとの会話のきっかけ、コミュニケーションにもなっているのだという。オンとオフの切り替えが、川口総支配人の笑顔の秘訣だろう。
                  </p>
                  <p>
                    これでまた、明日もお客様をお迎えできる。
                    <br />
                    長い一日が終わった。今日も一日お疲れ様でした。
                  </p>
                  <p>「ホテルマン」が大切にする10のマイルール。</p>
                  <p>
                    その裏側には、経験から磨かれたプロの視点、信念、そしてなによりもお客様と向き合う姿勢があった。川口総支配人の言葉から、ザ
                    ロイヤルパークホテル
                    京都三条がこの10年おもてなしの街、京都で愛されてきた理由がわかった気がする。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article02/img_chapter05.png",
            },
            imgSp: {
              src: "/assets/images/journal/article02/img_chapter05__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "05",
            },
            title: (
              <>
                <span>ザ ロイヤルパークホテル </span>
                <span>京都三条 10周年に寄せて</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>「川口、今なにしてるんだ？」</p>
                  <p>
                    そんな突然のお電話を京都からもらったのは、今から10年ほど前。
                    「ロイヤルパークイン名古屋（2011年閉館）」でお世話になった、前総支配人からの電話に驚きつつも、久しぶりの会話に喜びを感じました。
                    「京都に、こないか？」冗談とも本気ともとれるような突然のお誘いに、わたしは当時戸惑ったことを記憶しています。なぜならその頃のわたしは名古屋の別ホテルで働いており、結婚を控えていた妻は新天地となる職場への転職が決まっていたため、移住などとうてい無理だろう、そう考えていました。
                  </p>
                  <p>
                    しかしその数ヶ月後、不思議なご縁に導かれ39歳で京都の地へやってまいりました。
                    新たな土地でいちからはじめる。大きな決断に不安もありましたが、反面とてもワクワクと、心は踊っていました。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter05_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    当館のオープンは奇しくも東日本大震災の年。
                    <br />
                    建築資材が届かずオープン日程が遅延するなどスタートから苦労があったそうです。
                  </p>
                  <p>
                    グループとしてプレミアム宿泊主体型の「THEシリーズ」に取り組み始め、京都三条は福岡に続き2館目となるオープン。今でこそ、400を超えるホテルが点在する京都ですが、10年前はビジネスホテルの数もまだ多くありませんでした。
                    もっと身近にビジネスも観光も、宿でゆっくりと休み、そして美味しい朝食をお楽しみいただく。このシンプルなスタイルは当時大変話題になったと聞いています。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter05_02.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    わたくしたちスタッフは、日々慣れない業務やオペレーションに戸惑いながら、チームとして歩んできました。
                  </p>
                  <p>
                    —
                    京都観光にいらっしゃるお客様のために、オリジナルマップを作ってみよう
                    <br />—
                    おすすめできる飲食店をジャンルごとにリスト化してみよう
                    <br />—
                    休みの日に訪れた京都の観光スポットの情報は、みんなで共有しよう
                  </p>
                  <p>
                    地道ではありますが、現場発信で考えられる限りの策に取り組みました。
                    「街と、もてなす。」コンセプトである、この言葉をスタッフ一人ひとりが解釈しながら。
                  </p>
                  <p>
                    その後海外からのお客様が爆発的に増え、京都の街も観光バブルに沸きました。
                    各国から訪れるお客様に対応するため英語の猛勉強や、海外式の朝食などさまざまな工夫にスタッフ一同取り組んでまいりました。
                  </p>
                  <p>
                    そして2020年—。
                    <br />
                    新型コロナウイルスの影響から、ご予約いただくのは一日数組のみ……。スタッフの士気や気持ちが落ち込むこともありました。
                    しかし、そういう状況下でも来ていただけるお客様には、しっかりとサービスを提供しなければいけない。総支配人としての立場からスタッフを励まし続けました。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter05_03.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ホテル業界は時代の波の中、常に順応していかなければならない業界です。
                    <br />
                    それは、オペレーションや内装、朝食メニューひとつをとってもそうです。常に進化を続けるため、歩みを止めるわけにはいきません。そんな移り変わりの早い業界の中で、10年もお客様をお迎えできたこと、そしてさまざまな方とのつながりには、感謝しかありません。
                  </p>
                  <p>
                    わたしたちザ ロイヤルパークホテル
                    京都三条はこれからも変わらずお客様をお迎えいたします。これまでお越しいただいた方々、そしてこれからいらっしゃる方々の期待に応えられるよう、そして、次の世代にこのご縁をつないでいってもらえるよう。
                    さらなる努力を重ね、次なる10年を歩んでまいります。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article02/img_chapter05_04.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2021年11月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_fwb u_tac u_mt80">ザ ロイヤルパークホテル 京都三条</p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/the/kyotosanjo/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
